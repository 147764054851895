@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --primaryClr: #ffb600;
  --secondaryClr: #222a34;
  --headClr: #101520;
  --bodyClr: #1a1e29;
  --whiteClr: #ffffff;
  --blackClr: #000000;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
  --siteRedClr: #d9534f;
}
.light:root {
  --primaryClr: #ffb600;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

body {
  background: #eee;
  color: #1e1e1e;
  font-size: 12px;
  font-family: Helvetica, Tahoma, sans-serif;
  // font-family: Tahoma, Helvetica, sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
.btn-primary {
  background: var(--primaryClr);
  color: var(--blackClr);
  border-color: var(--primaryClr);
  font-size: 12px;
  font-weight: 600;
  border-radius: 50px;
  padding: 6px 8px;
  &:hover,
  &:focus {
    background: var(--primaryClr) !important;
    color: var(--blackClr) !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.cust-container {
  max-width: 1179px;
  margin: 0 auto;
}
.container1350 {
  max-width: 1350px;
  padding: 0;
  margin: 0 auto;
  position: relative;
}
.btn-outline-primary {
  border-color: #ffbd00;
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: #ffbd00 !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--primaryClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
  border: 1px solid var(--primaryClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--blackClr);
  }
}
img {
  width: 100%;
  height: auto;
}
input,
input.form-control {
  border-radius: 0;
  border-color: #ffbd00;
  font-size: 14px;
  font-weight: 300;
  &:focus {
    box-shadow: none !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
  -webkit-text-fill-color: var(--bodyClr) !important;
  outline: 0 !important;
  border: 0 !important;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: #000;
  background-image: linear-gradient(180deg, #383838 0%, #010101 100%);
  margin-bottom: 1px;
  @media only screen and (max-width: 767.98px) {
    background-image: linear-gradient(180deg, #474747 0%, #070707 100%);
  }
  .top-header {
    width: 100%;
    height: 74px;
    margin: 0 auto;
    padding-top: 20px;
    @media only screen and (max-width: 767.98px) {
      width: 100vw;
      height: 14.6666666667vw;
      // line-height: 14.6666666667vw;
      padding: 2.6666666667vw 1.8666666667vw 2.6666666667vw 0;
    }
  }
  .container-fluid {
    max-width: calc(100% - 40px);
    padding: 0;
    @media only screen and (min-width: 1400px) {
      min-width: 1350px;
    }
    @media only screen and (max-width: 767.98px) {
      max-width: calc(100% - 0px);
    }
    .row {
      @media only screen and (max-width: 767.98px) {
        margin: 0;
      }
      .col-12 {
        @media only screen and (max-width: 767.98px) {
          padding: 0;
        }
      }
    }
  }
  .bot-header {
    background-image: linear-gradient(180deg, #ffcc2e 0%, #ffbd14 100%);
  }
  .middle-part {
    .navbar {
      .navbar-collapse {
        background-color: transparent !important;
      }
    }
  }
  .logo {
    a {
      display: block;
      max-width: 110px;
      @media screen and (max-width: 767.98px) {
        width: 24vw;
        margin: -1.0666666667vw 0 0 1.8666666667vw;
      }
    }
  }
  .searchbar {
    position: relative;
    height: fit-content;
    margin-left: 15px;
    img {
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      width: 19px;
      height: 19px;
    }
    .form-control {
      background: var(--whiteClr) !important;
      color: var(--bodyClr) !important;
      font-size: 12px;
      width: 280px;
      height: 25px;
      margin: 0;
      padding: 5px;
      padding-left: 25px;
      border: 0px #aaa solid;
      border-radius: 4px;
      box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.5);
    }
    .search-clear {
      position: absolute;
      top: 50%;
      right: 2px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border: 0;
      background: #edeced;
    }
  }
  .navbar {
    padding: 0;
    .navbar-collapse {
      background: var(--secondaryClr);
      .navbar-nav {
        .nav-link {
          color: var(--blackClr);
          font-size: 12px;
          font-weight: bold;
          line-height: 30px;
          position: relative;
          padding: 0 10px;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          svg,
          img {
            fill: var(--whiteClr);
            width: 20px;
            height: 20px;
          }
          &:hover {
            background: rgba(255, 255, 255, 0.2);
          }
          &.active {
            background: #ffdc7a;
            box-shadow: inset 0 0px 5px 0 rgba(83, 33, 33, 0.5);
          }
          &.new {
            background-image: linear-gradient(#4b4b4b, #1e1e1e);
            color: var(--whiteClr);
            padding: 0 8px 0 20px;
            border-color: #383838 !important;
            img {
              width: 18px;
              height: 18px;
              margin: -4px 0 0 2px;
              &.new-text {
                position: absolute;
                top: 4px;
                left: -2px;
                width: 29px;
                height: 29px;
              }
            }
          }
          .live-match {
            position: absolute;
            top: -6px;
            right: 3px;
            min-width: 33px;
            height: 12px;
            background: var(--whiteClr);
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 3px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
            img {
              width: 18px;
              height: 8px;
              padding: 0 2px;
              margin: 0 auto;
              animation: twinkling 1.4s infinite ease;
            }
            span {
              background-image: linear-gradient(
                180deg,
                #fb3434 0%,
                #e80505 100%
              );
              color: var(--whiteClr);
              padding: 0 3px;
              border-radius: 0 3px 3px 0;
            }
          }
        }
      }
    }
  }
  .right-section {
    .oneclick-bet {
      background: linear-gradient(180deg, #4b4b4b 0%, #1e1e1e 100%);
      height: 33px;
      line-height: 28px;
      margin-top: -3px;
      padding: 0 13px 0 10px;
      border-top: 3px solid var(--primaryClr);
      &:hover {
        background: linear-gradient(0deg, #4b4b4b 0%, #1e1e1e 100%);
      }
      &:focus,
      &.checked {
        background: linear-gradient(
          180deg,
          #02bd58 0%,
          #06883f 100%
        ) !important;
      }
      .form-check-input {
        background: rgba(255, 255, 255, 0.2);
        width: 16px;
        height: 16px;
        margin-top: 7px;
        border-color: transparent;
        &.is-valid:checked,
        .was-validated .form-check-input:valid:checked {
          background-color: #198754;
          border-color: rgba(255, 255, 255, 0.4);
        }
      }
      .form-check-label {
        color: #ffc828 !important;
        font-size: 12px;
        font-weight: 600;
        padding: 1px 0 0 6px;
      }
    }
    .settings {
      .dropdown {
        .dropdown-toggle {
          background: transparent;
          display: flex;
          align-items: center;
          height: 30px;
          padding: 0 9px 0 10px;
          border: 0;
          border-radius: 0;
          &:hover,
          &:focus {
            background: rgba(255, 255, 255, 0.2) !important;
          }
          &::after {
            content: url("./assets/images/setting-icon.svg");
            margin: 2px 1px 0 9px;
            border: 0;
          }
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    @media only screen and (max-width: 767.98px) {
      width: 55%;
      padding-right: 1.8666666667vw;
    }

    form {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 767.98px) {
        width: 100%;
      }
      .form-group {
        margin: 0 5px 2px 0;
        position: relative;
        @media only screen and (max-width: 767.98px) {
          margin: 0;
        }
        &.user {
          display: flex;
          align-items: center;
          img {
            width: 13px;
            height: auto;
            margin-right: 8px;
          }
        }
        .form-control {
          background: var(--whiteClr) !important;
          color: var(--bodyClr) !important;
          font-size: 12px;
          font-family: Tahoma, Helvetica, sans-serif;
          width: 130px;
          height: 25px;
          margin: 0;
          padding: 5px;
          outline: 0 !important;
          border: 0px #aaa solid;
          border-radius: 4px;
          box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.5);
          box-sizing: border-box;
          // box-sizing: border-box;
          &:focus {
            border-width: 1px;
            border-color: #2789ce;
            box-shadow: 0 0 4px 2px rgba(114, 187, 239, 0.8);
          }
          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
        }
        .validation {
          position: absolute;
          top: 0px;
          right: -10px;
          width: 55px;
          height: 16px;
          color: var(--blackClr);
          font-size: 15px;
          font-weight: 900;
        }
      }
      .btns {
        margin-left: 2px;
        @media only screen and (max-width: 767.98px) {
          width: 100%;
          flex-direction: row-reverse;
        }
        .form-group {
          @media only screen and (max-width: 767.98px) {
            width: 100%;
          }
          button {
            color: var(--whiteClr) !important;
            font-size: 12px;
            font-weight: bold;
            width: 80px;
            height: 25px;
            line-height: 13px;
            text-decoration: none;
            border: 0;
            border-radius: 4px;
            @media only screen and (max-width: 767.98px) {
              font-size: 3.4666666667vw;
              width: 100%;
              max-width: 28.8vw;
              height: 8.5333333333vw;
              line-height: 8.5333333333vw;
              padding: 0 !important;
              border: 0.2666666667vw solid transparent;
              border-radius: 1.0666666667vw;
              box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.5);
            }
            &.login-bnt {
              background-color: #e83523;
              background-image: linear-gradient(
                -180deg,
                #f72424 0%,
                #bb1c00 100%
              ) !important;
              @media only screen and (max-width: 767.98px) {
                margin-left: 0.5333333333vw !important;
                border-color: #710b0b !important;
              }
              img {
                width: 10px;
                height: 11px;
                margin: -2px 0 0 6px;
                @media only screen and (max-width: 767.98px) {
                  width: 4.2666666667vw;
                  height: 4.2666666667vw;
                  margin-right: 0.5333333333vw;
                }
                &.d_sm_none {
                  @media only screen and (max-width: 767.98px) {
                    display: none;
                  }
                }
              }
            }
            &.signup {
              background-color: #666;
              background-image: linear-gradient(
                -180deg,
                #666666 0%,
                #333333 100%
              ) !important;
              @media only screen and (max-width: 767.98px) {
                border-color: #000 !important;
              }
            }
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .funds {
      display: flex;
      align-items: center;
      margin-right: 15px;
      border: 1px solid var(--blackClr);
      @media only screen and (max-width: 767.98px) {
        margin-right: 2.1333333333vw;
        border: 0;
        border-radius: 1.0666666667vw;
      }
      ul {
        background: rgba(255, 255, 255, 0.1);
        height: 24px;
        line-height: 24px;
        padding: 0 7px;
        margin-right: 0;
        cursor: pointer;
        border-radius: 4px 0 0 4px;
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
        @media only screen and (max-width: 767.98px) {
          font-size: 2.6666666667vw;
          height: 9.3333333333vw;
          line-height: 3.2vw;
          padding: 1.0666666667vw 1.0666666667vw 0 1.8666666667vw;
          border: 0.2666666667vw solid var(--blackClr);
          border-radius: 1.0666666667vw 0 0 1.0666666667vw;
        }
        li {
          position: relative;
          margin: 0 7px 0 0 !important;
          a {
            color: var(--primaryClr);
            span {
              @media only screen and (max-width: 767.98px) {
                font-size: 2.6666666667vw !important;
              }
              &:first-child {
                font-size: 12px;
                letter-spacing: -0.008em;
                opacity: 0.7;
              }
              &:last-child {
                font-size: 12px;
                font-weight: bold;
                @media only screen and (max-width: 767.98px) {
                  color: #ffb200;
                }
              }
              &.bal-text {
                @media only screen and (max-width: 767.98px) {
                  display: none;
                }
              }
            }
            &.main-bal {
              margin: 0 7px 0 0 !important;
            }
          }
          &.balance {
            @media only screen and (max-width: 767.98px) {
              display: grid;
              line-height: 3.7vw;
            }
          }
          &.bal-count {
            color: #ffb600;
            font-weight: bold;
            font-size: 11px;
            height: 16px;
            line-height: 14px;
            letter-spacing: 1px;
            padding: 0 8px 0 7px;
            margin-right: 0 !important;
            border: 1px solid rgba(255, 182, 0, 0.5);
            border-radius: 3px;
            @media only screen and (max-width: 767.98px) {
              height: 3.7333333333vw;
              font-size: 2.9333333333vw;
              line-height: 3.7333333333vw;
              border-width: 0.2666666667vw;
              border-radius: 0.8vw;
              padding: 0 2.1333333333vw;
            }
            span {
              font-size: 10px;
              @media only screen and (max-width: 767.98px) {
                font-size: 2.6666666667vw;
                font-weight: bold;
                margin-left: 0.2666666667vw;
              }
            }
          }
        }
        &:hover {
          li {
            a {
              text-decoration: underline;
            }
          }
        }
      }
      .refresh {
        // background: rgba(255, 255, 255, 0.1);
        background: #525252;
        width: 25px;
        height: 24px;
        display: block;
        text-align: center;
        border-left: 1px solid #000;
        border-radius: 0 4px 4px 0;
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
        @media only screen and (max-width: 767.98px) {
          background: rgba(255, 255, 255, 0.1);
          font-weight: bold;
          width: auto;
          height: 9.3333333333vw;
          line-height: 5.3333333333vw;
          display: table;
          padding: 1.6vw 1.8666666667vw;
          border: 0.2666666667vw solid var(--blackClr);
          border-left-width: 0;
          border-radius: 0 1.0666666667vw 1.0666666667vw 0;
          box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.3);
        }
        img {
          width: 14px;
          height: 14px;
          @media only screen and (max-width: 767.98px) {
            width: 5.0666666667vw;
            height: 5.0666666667vw;
          }
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        top: -4px;
        background-color: var(--whiteClr);
        width: 222px;
        padding: 0;
        border-radius: 4px;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
        .user {
          color: #3b5160;
          font-size: 12px;
          font-weight: bold;
          line-height: 27px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          margin-bottom: 0;
          border-bottom: 1px solid #7e97a7;
          .gmt {
            font-size: 11px;
            padding: 0 5px;
            margin-left: auto;
            border-left: 1px solid #c5d0d7;
          }
        }
        .dropdown-item {
          font-size: 12px;
          line-height: 25px;
          padding: 0 10px;
          border-bottom: 1px solid #e0e6e6;
          &.logout {
            padding: 7px 10px 5px;
            border-bottom: 0;
            span {
              background-color: #7e97a7;
              color: var(--whiteClr);
              font-weight: bold;
              text-transform: uppercase;
              display: inherit;
              text-align: center;
              line-height: 25px;
              padding: 0 10px;
              border-radius: 4px;
              img {
                width: 10px;
                height: 11px;
              }
            }
          }
          &:hover,
          &.active,
          &:active {
            background: #eff2f2;
          }
        }
      }
    }
    ul {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 5px;
        position: relative;
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      background: rgba(255, 255, 255, 0.1);
      color: var(--primaryClr);
      font-weight: 400;
      width: 112px;
      height: 27px;
      line-height: 25px;
      padding: 0;
      border: 1px solid var(--blackClr);
      border-radius: 4px;
      box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.3);
      @media only screen and (max-width: 767.98px) {
        font-weight: bold;
        width: auto;
        height: 9.3333333333vw;
        line-height: 5.3333333333vw;
        padding: 1.6vw 1.8666666667vw;
        margin-left: 0;
        border-width: 0.2666666667vw;
        border-radius: 1.0666666667vw;
        box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.3);
      }
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.1) !important;
        color: var(--primaryClr) !important;
        border-color: var(--blackClr) !important;
      }
      &.dropdown-toggle {
        &::after {
          position: relative;
          right: -8px;
          top: 1.5px;
          border-top: 0.425em solid;
        }
        &::after,
        .my-account {
          @media only screen and (max-width: 767.98px) {
            display: none;
          }
        }
        .setting {
          @media only screen and (min-width: 768px) {
            display: none;
          }
          width: 5.0666666667vw;
          height: 5.0666666667vw;
          display: block;
          text-align: end;
          img {
            @media only screen and (max-width: 767.98px) {
              width: 100%;
              height: auto;
              margin-right: 0;
            }
          }
        }
      }
      img {
        width: 13px;
        height: 12px;
        margin: -2px 5px 0 -8px;
        fill: var(--primaryClr);
      }
    }
  }
  &.aftrlgn {
    .headerRight {
      .my-info {
        display: flex;
        align-items: center;
        margin-left: auto;
      }
      .my-bets {
        @media only screen and (min-width: 768px) {
          display: none;
        }
        a {
          background-color: rgba(255, 255, 255, 0.1);
          color: #ffb200;
          font-weight: bold;
          min-width: 29.3333333333vw;
          max-width: 31.2vw;
          height: 9.3333333333vw;
          line-height: 5.3333333333vw;
          display: inline-table;
          text-align: center;
          padding: 1.8666666667vw 0 1.3333333333vw;
          margin-left: -1px;
          border: 0.2666666667vw solid var(--blackClr);
          border-left-width: 0;
          border-radius: 0 1.0666666667vw 1.0666666667vw 0;
          box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.3);
          img {
            width: 5.3333333333vw;
            height: 5.0666666667vw;
            margin-right: 1.3333333333vw;
          }
        }
      }
    }
  }
}
.news {
  background: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
  color: var(--whiteClr);
  height: 25px;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  overflow: hidden;
  @media only screen and (max-width: 767.98px) {
    margin-top: 0;
  }
  .news-text {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    width: 77px;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: "";
      height: 17px;
      width: 17px;
      margin-right: 2px;
      background: url(./assets/images/news-mic.png) no-repeat;
    }
    &:after {
      content: "";
      position: absolute;
      right: -7px;
      top: 0;
      z-index: 2;
      background-image: url(./assets/images/news-arrow.png);
      background-repeat: no-repeat;
      width: 7px;
      height: 25px;
    }
  }
  a {
    color: #6ac2ff !important;
    font-size: 12px;
    font-weight: bold;
    height: 25px;
    line-height: 25px;
    margin-right: 100px;
    align-items: center;
    cursor: pointer;
    span {
      height: 15px;
      line-height: 15px;
      font-size: 11px;
      font-style: italic;
      font-weight: normal;
      color: #1c2834;
      background: #6ac2ff;
      border-radius: 2px;
      padding: 0 5px;
      margin-right: 5px;
    }
    &:hover {
      color: #6ac2ff;
      text-decoration: underline;
    }
  }
}
.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
}
.offcanvas {
  border: none !important;
  z-index: 999999999;
}
.offcanvas-header {
  background: var(--headClr);
  color: var(--whiteClr);
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--whiteClr);
  }
  .user-info {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      margin: 0;
    }
  }
  .btn-close {
    opacity: 1;
  }
}
.offcanvas-body {
  background: var(--bodyClr);
  .balance {
    font-size: 16px;
    color: var(--whiteClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--whiteClr);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  .menu-items {
    border-top: 1px solid var(--whiteClr);
    margin: 30px -1rem 0 -1rem;
    li {
      border-bottom: 1px solid var(--whiteClr);
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: var(--whiteClr);
        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
.main {
  position: relative;
  .home-content {
    overflow-y: scroll;
  }
  .TopCategories {
    margin: 0 0px 30px;
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("https://gbet567.com/images/sports.png");
          }
        }
      }
    }
  }
  .mainBanner {
    margin-bottom: 10px;
    @media only screen and (max-width: 767.98px) {
      margin-bottom: 0;
    }
    .slick-slide {
      img {
        width: 100%;
        height: auto;
      }
      .content {
        bottom: auto;
        font-size: 0.85rem;
        left: 0;
        position: absolute;
        right: auto;
        text-align: left;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        z-index: 99;
        h2 {
          @media screen and (min-width: 768px) {
            font-size: 2rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
          }
          @media screen and (max-width: 767px) {
            font-size: 1.15rem;
          }
          span {
            color: var(--primaryClr);
            margin: 0 8px;
          }
        }
        p {
          @media screen and (max-width: 767px) {
            font-size: 0.7rem;
          }
        }
      }
    }
    .slick-dots {
      bottom: 7px;
      li {
        width: 15.5px;
        height: 21px;
        margin: 0 0px;
        button {
          width: 10px;
          &::before {
            color: rgba(255, 255, 255, 0.3);
            font-size: 8px;
            width: 10px;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &::before {
              color: rgba(255, 255, 255, 0.8);
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .home_game_types {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    @media only screen and (max-width: 767.98px) {
      grid-gap: 1.6vw;
      padding: 0 1.6vw 1.6vw;
    }
    a {
      position: relative;
      @media only screen and (max-width: 767.98px) {
        grid-column: span 2;
      }
      &.half-width {
        grid-column: span 2;
        @media only screen and (max-width: 767.98px) {
          grid-column: span 4;
        }
      }
      .title {
        position: absolute;
        z-index: 4;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 47px;
        background-image: linear-gradient(
          270deg,
          rgba(69, 94, 104, 0) 4%,
          #000000 97%
        );
        border-bottom: 5px solid #ffb80c;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 767.98px) {
          height: 7.443vw;
        }
        h4 {
          color: var(--whiteClr);
          font-size: 18px;
          font-weight: 600;
          line-height: 1.8;
          text-indent: 10px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 0;
          @media only screen and (max-width: 767.98px) {
            font-size: 3.7333333333vw;
            text-indent: 1.3333333333vw;
          }
        }
        .play {
          background-image: url("./assets/images/play-bg-triangle.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left;
          color: var(--blackClr);
          font-size: 14px;
          font-weight: bold;
          width: 98px;
          height: 100%;
          line-height: 42px;
          text-indent: 25px;
          @media only screen and (max-width: 767.98px) {
            width: 18.1333333333vw;
            line-height: 7.4666666667vw;
            height: 6.8vw;
            text-indent: 3.7333333333vw;
            font-size: 2.9333333333vw;
          }
          &:after {
            position: absolute;
            z-index: -1;
            bottom: 0;
            right: 0;
            width: 74px;
            height: 100%;
            background-color: #ffb80c;
            content: "";
            @media only screen and (max-width: 767.98px) {
              width: 14.9333333333vw;
              height: 6.8vw;
            }
          }
        }
      }
    }
  }
  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    margin: 20px 0 10px;
    padding-bottom: 5px;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background: var(--headClr);
    margin: 0 -1rem;
    padding: 22px 1rem;
    color: var(--whiteClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      height: 33px;
      svg {
        display: none;
      }
    }
    h3 {
      border-bottom: 1px solid var(--whiteClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      margin: 0 -1rem;
      border-top: 1px solid var(--whiteClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--whiteClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
  }
  .myprofile-page {
    position: relative;
    .profile-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      img {
        max-width: 60px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid var(--primaryClr);
      }
      span {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .col-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 17.3611111111%;
    @media only screen and (max-width: 991.98px) {
      display: none;
    }
    .sub_path {
      background-color: var(--whiteClr);
      margin-bottom: 1px;
      border-bottom: 1px solid #7e97a7;
      position: relative;
      .path {
        background-image: linear-gradient(180deg, #000 0%, #000 100%);
        background-position: initial;
        color: var(--whiteClr);
        font-size: 12px;
        height: 25px;
        line-height: 25px;
        overflow: hidden;
        padding: 0 10px;
        position: relative;
        .path-back {
          position: absolute;
          left: 0;
          z-index: 2;
          width: 36px;
          height: 25px;
          // background: url(../images/left/path-back.png) no-repeat right;
          display: none;
        }
        ul {
          position: absolute;
          right: 0;
          width: 9999px;
          text-align: right;
          li {
            display: inline;
            // background: url(../images/left/bg-path_arrow.png) no-repeat right;
            padding: 4px 13px 5px 6px;
            &.path-last {
              background-image: none;
              padding-right: 6px;
            }
          }
        }
        a {
          color: var(--whiteClr);
          display: inline;
        }
      }
      .menu-list {
        li {
          list-style: none;
          border-bottom: 1px solid #eee1c0;
          a {
            color: #222;
            font-size: 12px;
            line-height: 25px;
            display: block;
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.select {
              background-color: rgba(0, 0, 0, 0.6);
              color: var(--whiteClr);
            }
          }
        }
      }
    }
  }
  .col-center {
    position: relative;
    margin-left: 17.3611111111%;
    margin-right: 26.0416666667%;
    padding: 0 15px;
    @media only screen and (max-width: 991.98px) {
      margin-left: 0;
    }
    h2 {
      color: #1e1e1e;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      padding-top: 6px;
      margin-bottom: 6px;
    }
    .event-left,
    .event-right {
      width: 49.5%;
      @media only screen and (max-width: 575.98px) {
        width: 100%;
      }
      .profile-wrap {
        background-color: var(--whiteClr);
        margin-bottom: 15px;
        border-bottom: 1px solid #7e97a7;
        h3 {
          background-color: #7e97a7;
          color: var(--whiteClr);
          font-size: 1.1em;
          font-weight: 600;
          line-height: 24px;
          padding: 0 10px;
          margin-bottom: 0;
        }
        ul {
          li {
            ul {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #e0e6e6;
              li {
                color: #243a48;
                font-size: 11px;
                min-height: 16px;
                padding: 5px 0 5px 10px;
                position: relative;
                &:first-child {
                  width: 125px;
                }
              }
            }
          }
        }
      }
    }
    .white-wrap {
      background-color: var(--whiteClr);
      color: #3b5160;
      padding: 7px 10px 5px;
      border-bottom: 1px solid #7e97a7;
      margin-bottom: 15px;
      .head-balance {
        width: 31.3725490196%;
        margin-right: 10px;
        border-right: 1px solid #d8d8d8;
        @media only screen and (max-width: 767.98px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          border-right-width: 0;
          border-bottom: 1px solid #d8d8d8;
        }
        h5 {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 7px;
        }
        .totalBalance {
          font-size: 30px;
          line-height: 36px;
          font-weight: bold;
          color: #2789ce;
          span {
            font-size: 12px;
            color: #7e97a7;
            font-weight: normal;
          }
        }
      }
      h3 {
        width: 85%;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 7px;
      }
      p {
        width: 85%;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 5px;
      }
      .acc-info {
        margin-bottom: 5px;
        li {
          display: inline-block;
          margin-right: 15px;
          padding-left: 20px;
          &.user {
            background-image: url(./assets/images/icon-user.png);
            background-repeat: no-repeat;
          }
          &.time {
            background-image: url(./assets/images/icon-time.png);
            background-repeat: no-repeat;
          }
        }
      }
    }
    &.report {
      padding-right: 0;
      margin-right: 0;
      @media only screen and (max-width: 991.98px) {
        padding-right: 15px;
      }
    }
    .nav-tabs {
      width: 40%;
      min-width: 375px;
      margin: 0 0 10px;
      border-left: 1px solid #3b5160;
      border-radius: 5px;
      @media only screen and (max-width: 575.98px) {
        width: 100%;
        min-width: auto;
      }
      .nav-item {
        width: 33.33%;
        list-style: none;
        border: 1px solid #3b5160;
        border-width: 1px 0;
        &:first-child {
          border-radius: 4px 0 0 4px;
          .nav-link {
            border-radius: 3px 0 0 3px;
          }
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        .nav-link {
          background: var(--whiteClr);
          color: #3b5160;
          font-size: 13px;
          font-weight: bold;
          line-height: 27px;
          width: 100%;
          margin: 0;
          padding: 0;
          border: 0;
          border-right: 1px solid #3b5160;
          border-radius: 0;
          &.active {
            background: #3b5160;
            color: var(--whiteClr);
          }
        }
      }
    }
    .tab-content {
      .nav-tabs {
        width: 100%;
        margin-bottom: 0;
        margin-top: 15px;
        flex-wrap: nowrap;
        overflow-x: auto;
        border: 0;
        position: relative;
        &:before {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: #3b5160;
        }
        .nav-item {
          display: inline-block;
          width: auto;
          margin-right: 3px;
          border: 0;
          .nav-link {
            background: var(--whiteClr);
            color: #3b5160;
            font-size: 15px;
            font-weight: bold;
            line-height: 15px;
            width: max-content;
            padding: 5px 10px 9px 10.5px;
            border: 1px solid #3b5160;
            border-radius: 3px 3px 0 0;
            cursor: pointer;
            &.active {
              background: #3b5160;
              color: var(--whiteClr);
            }
          }
        }
      }
    }
  }
  .table-caption {
    background-color: #3b5160;
    border-bottom: 1px solid #7e97a7;
    color: var(--whiteClr);
    line-height: 24px;
    font-weight: bold;
    padding: 0 10px;
  }
  .table01,
  .table-s {
    width: 100%;
    background-color: var(--whiteClr);
    text-align: right;
    border-collapse: collapse;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
    th {
      color: #243a48;
      background-color: #e4e4e4;
      border: 1px solid #7e97a7;
      border-width: 1px 0 1px 0;
      padding: 8px 10px;
      @media only screen and (max-width: 991.98px) {
        min-width: 120px;
      }
    }
    td {
      color: #1e1e1e;
      font-size: 12px;
      line-height: 15px;
      padding: 8px 10px;
      vertical-align: middle;
      border-top: 1px solid #eee;
    }
    .align-L {
      text-align: left;
    }
    .no-data {
      background: var(--whiteClr);
      padding: 10px 10px 5px !important;
      text-align: left;
      p {
        margin-bottom: 7px;
      }
    }
  }
  .table-s {
    td,
    th {
      padding: 3px 5px 4px;
    }
  }
  .function-wrap {
    position: relative;
    padding: 10px 10px 0;
    background-color: #e0e6e6;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 10px;
    .input-list,
    .filter-list {
      margin-bottom: 5px;
      margin-right: 5px;
      li {
        display: inline-block;
        list-style: none;
        line-height: 25px;
        margin: 0 5px 5px 0;
        white-space: nowrap;
        label {
          margin-right: 5px;
        }
      }
    }
    .input-list {
      display: block;
      .btn {
        background-image: linear-gradient(180deg, #ffffff 0%, #eeeeee 89%);
        color: #1e1e1e;
        font-size: 12px;
        font-weight: normal;
        width: 97px;
        line-height: 23px;
        margin: 0;
        padding: 0;
        border: 1px solid #bbb;
        border-radius: 4px;
        box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.5);
      }
      .btn-send {
        background-image: linear-gradient(180deg, #474747 0%, #070707 100%);
        color: #ffb600 !important;
        font-size: 12px;
        font-weight: bold;
        width: 97px;
        line-height: 23px;
        text-align: center;
        display: block;
        border: 1px solid #222;
        border-radius: 4px;
        box-shadow: initial;
      }
    }
    select {
      width: 120px;
      height: 25px;
      line-height: 25px;
      cursor: pointer;
      margin: 0 5px 5px 0;
      option {
        padding: 3px;
        color: #222;
      }
    }
  }
  input,
  textarea {
    font-family: Tahoma, Helvetica, sans-serif;
    color: #1e1e1e;
    font-size: 12px;
    border: 0px #aaa solid;
    background: #fff;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 5px;
    margin: 0 5px 5px 0;
    box-sizing: border-box;
    &[type="checkbox"],
    &[type="radio"] {
      width: auto !important;
      height: auto !important;
      border-width: 0;
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0) !important;
      padding: 0 !important;
      box-shadow: none;
    }
    &.disable,
    &.disable {
      background-color: #dcdcdc;
      color: #666;
      box-shadow: none;
      border: 1px solid #bbb;
    }
  }
  .cal-input {
    width: 110px;
    height: 24px;
    cursor: pointer;
    background: var(--whiteClr) url(./assets/images/btn-cala.png) no-repeat;
    background-position: right;
    &[type="date"]::-webkit-inner-spin-button,
    &[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
  .time-input {
    width: 45px;
    height: 24px;
  }
  .noReportMessage {
    p {
      line-height: 1.22;
      margin-bottom: 7px;
    }
  }
  .edit-btn {
    background: var(--whiteClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--whiteClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-info {
    th {
      background: var(--headClr);
      color: var(--whiteClr);
    }
    td {
      background: var(--bodyClr);
      color: var(--whiteClr);
    }
    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}
.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    svg {
      fill: var(--whiteClr);
    }
  }
}
.steps-canvas {
  .offcanvas-header {
    background: var(--headClr);
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }
  .form-steps {
    padding: 55px 30px 0;
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }
    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--whiteClr);
    }
    input {
      background: var(--headClr);
      border-radius: 10px;
      border-color: var(--headClr);
      padding: 14px 20px;
      color: var(--whiteClr);
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: var(--whiteClr);
    }
    .btn {
      padding: 9px 30px;
    }
    .form-link {
      font-size: 14px;
      color: var(--lightClr);
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
.tabs-area {
  margin-top: 10px;
  ul {
    margin: 0 -1rem;
    flex-wrap: nowrap;
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: #f3f3f3 !important;
          color: #26324b !important;
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--whiteClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      .top-area {
        background: var(--whiteClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    .upi-list {
      margin-top: 40px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }
    form {
      padding: 55px 30px 0;
      text-align: center;
      input {
        background: var(--headClr);
        border-radius: 10px;
        border-color: var(--headClr);
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 115px;
      background: var(--whiteClr);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-height: 40px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}
.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--secondaryClr);
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 5px;
        color: var(--whiteClr);
        font-weight: 500;
        border: none;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      // margin-bottom: 10px;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      border: 1px solid var(--headClr);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
      padding-bottom: 1rem;
      border-radius: 0 0 0.5rem 0.5rem;
      .match-listing-head {
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px 0 10px;
        border-bottom: 1px solid #e0e6e6;
        background: #fff;
        height: 25px;
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // width: calc(100% - (60px) * 5);
          width: calc(100% - 275px);
          padding: 3px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: #2789ce;
            cursor: pointer;
            span {
              font-size: 12px;
              color: #777;
              font-weight: 400;
            }
          }
        }
        .status {
          background: #0a92a5;
          color: #c5f6ee;
          font-size: 11px;
          font-weight: 600;
          font-style: italic;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          &:after {
            content: "";
            background-image: url("./assets/images/fancy-icon.svg");
            background-size: cover;
            width: 12px;
            height: 12px;
            display: block;
            margin: 2px auto 0;
          }
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li {
            margin: 0 1px;
            width: 42px;
            height: 21px;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              color: var(--blackClr);
              font-size: 10px;
              width: 100%;
              padding: 4px 0px;
              cursor: pointer;
              height: 21px;
              border-radius: 0;
              span {
                font-size: 11px;
                line-height: 13px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.sports_page {
  .tab-content {
    .tab-pane {
      height: calc(100vh - 135px);
      overflow-y: auto;
    }
  }
}
.page-details {
  .match_details_container {
    background: var(--secondary2);
    padding-top: 15px;
    .math_info {
      text-align: center;
      .match_type {
        background: linear-gradient(
          to right,
          #f7a204 0,
          #ffc230 15%,
          #ffc230 30%,
          #f7a204 55%,
          #f7a204 100%
        );
        color: var(--tertiory1);
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
        word-spacing: 3px;
        width: fit-content;
        height: 34px;
        line-height: 34px;
        padding: 0 15px;
        margin: 0 auto;
        border-radius: 25px;
        position: relative;
        z-index: 1;
        animation: shine 3s linear infinite;
        @keyframes shine {
          0% {
            -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
            box-shadow: 0 0 0 0 var(--primaryClr);
          }
          40% {
            -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
            box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          }
          80% {
            -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
            box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          }
          100% {
            -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
            box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          }
        }
        &:after {
          position: absolute;
          content: "";
          top: 16px;
          left: -40%;
          background: var(--primaryClr);
          width: 180%;
          height: 2px;
          z-index: -1;
          @media screen and (max-width: 767px) {
            width: 140%;
            left: -20%;
          }
        }
      }
      .team_names {
        margin: 15px 0 10px;
        span {
          color: var(--primaryClr);
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          @media screen and (max-height: 575.98px) {
            font-size: 13px;
          }
          span {
            color: rgb(237, 33, 58);
            font-size: 12px;
            @media screen and (max-height: 575.98px) {
              font-size: 10px;
            }
          }
          &:last-child {
            span {
              display: none;
            }
          }
        }
      }
      .collapse_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        .btn {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
          border: 0;
          border-radius: 5px;
          padding: 4px 6px;
          &:hover,
          &:focus {
            background: linear-gradient(
              to right,
              #1186d3 0,
              #41aaf0 15%,
              #41aaf0 30%,
              #1186d3 55%,
              #1186d3 100%
            ) !important;
          }
          img {
            width: 14px;
          }
        }
        .in_play {
          background: var(--secondary2);
          color: #41aaf0;
          text-transform: uppercase;
          letter-spacing: 2px;
          overflow: hidden;
          font-weight: 700;
          margin: 0 15px;
          padding: 2px 6px;
          position: relative;
          .anim_line1 {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(to right, #171618, #1186d3);
            animation: animate1 2s linear infinite;
          }
          .anim_line2 {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 1px;
            background: linear-gradient(to bottom, #171618, #1186d3);
            animation: animate2 2s linear infinite;
            animation-delay: 1s;
          }
          .anim_line3 {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(to left, #171618, #1186d3);
            animation: animate3 2s linear infinite;
          }
          .anim_line4 {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 1px;
            background: linear-gradient(to top, #171618, #1186d3);
            animation: animate4 2s linear infinite;
            animation-delay: 1s;
          }
        }
      }
    }
    .sports-match-iframe {
      height: 100%;
      @media screen and (min-width: 1400px) {
        height: 390px;
      }
      @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
        height: 330px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
        height: 361px;
      }
      @media screen and (min-width: 992px) and (max-width: 1023.98px) {
        height: 311px;
      }
      @media screen and (min-width: 880px) and (max-width: 991.98px) {
        height: 391px;
      }
      @media screen and (min-width: 768px) and (max-width: 879.98px) {
        height: 361px;
      }
      @media screen and (min-width: 650px) and (max-width: 767.98px) {
        height: 321px;
      }
      @media screen and (min-width: 576px) and (max-width: 649.98px) {
        height: 291px;
      }
      @media screen and (min-width: 480px) and (max-width: 575.98px) {
        height: 381px;
      }
      @media screen and (min-width: 420px) and (max-width: 479.98px) {
        height: 350px;
      }
      @media screen and (min-width: 380px) and (max-width: 419.98px) {
        height: 330px;
      }
      @media screen and (min-width: 360px) and (max-width: 379.98px) {
        height: 310px;
      }
      @media screen and (min-width: 320px) and (max-width: 359.98px) {
        height: 290px;
      }
      @media screen and (max-width: 319.98px) {
        height: 280px;
      }
    }
  }
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
      padding-left: 10px;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background-color: #ffbd00;
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      margin-bottom: 0;
      background-color: #3b5160;
      margin-top: 0 !important;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 8px;
        line-height: normal;
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--blackClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
  }
  .game-points {
    border: 1px solid var(--headClr);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 0px 0 0px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
        }
        .team-name {
          display: flex;
          flex-direction: column;
          line-height: normal;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--whiteClr);
            text-align: center;
            border-radius: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin-left: 0px;
          border-radius: 0px;
          padding: 5px 2px;
          border-right: 1px solid #000;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.footer {
  padding: 26px 0 40px;
  margin: 0px auto;
  overflow-y: scroll;
  @media only screen and (max-width: 991.98px) {
    padding-bottom: 20vw;
  }
  .support_sec {
    display: block;
    max-width: 690px;
    margin: 0 auto 20px auto;
    padding: 5px 0px;
    border-radius: 8px;
    @media only screen and (max-width: 767.98px) {
      width: 84vw;
      margin: 0 auto 6.4vw;
    }
    .support-box {
      display: flex;
      justify-content: space-between;
      line-height: 22px;
      @media only screen and (max-width: 767.98px) {
        flex-wrap: wrap;
      }
      &:last-child {
        @media only screen and (max-width: 767.98px) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 8px;
          width: 84vw;
          margin: 0 auto;
        }
      }
      img {
        width: 26px;
        height: auto;
        margin-right: 5px;
        @media only screen and (max-width: 767.98px) {
          width: 5.8666666667vw;
          height: 5.8666666667vw;
          margin-right: 1.3333333333vw;
          filter: brightness(0.7);
        }
        &.insta {
          width: 22px;
        }
      }
      a {
        color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
        line-height: 40px;
        margin-right: 8px;
        @media only screen and (max-width: 767.98px) {
          color: rgba(0, 0, 0, 0.7);
          font-size: 2.9333333333vw;
          line-height: 6.6666666667vw;
        }
        &:nth-child(3) {
          padding-left: 10px;
          border-left: 1px solid rgba(0, 0, 0, 0.5);
        }
      }
    }
    .inner-box {
      background: var(--whiteClr);
      flex-basis: 49.277%;
      font-size: 13px;
      text-align: center;
      padding: 8px 0;
      margin-bottom: 8px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      border-radius: 8px;
      @media only screen and (max-width: 767.98px) {
        flex-basis: 100%;
        border: 0;
      }
      img {
        @media only screen and (max-width: 767.98px) {
          width: 8vw;
          height: 8vw;
          margin-right: 1.0666666667vw;
        }
      }
    }
    .social-box {
      background-color: var(--whiteClr);
      flex-basis: 32.29%;
      text-align: center;
      padding: 8px 0;
      margin-bottom: 8px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      border-radius: 8px;
      @media only screen and (max-width: 767.98px) {
        border: 0;
      }
    }
  }
  .power-wrap {
    background-color: rgba(0, 0, 0, 0);
    // background: rgba(255, 255, 255, 0.25);
    color: rgba(0, 0, 0, 0.6);
    max-width: 692px;
    padding: 5px 10px;
    margin: 0 auto 30px;
    display: flex;
    align-items: stretch;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    h3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      padding-right: 7px;
      margin-right: 7px;
      margin-bottom: 0px;
      span {
        font-size: 10px;
        font-weight: normal;
        line-height: 15px;
      }
      img {
        height: 18px;
        width: 107px;
      }
    }
    .licence_embed {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      margin-right: 5px;
    }
    p {
      color: rgba(0, 0, 0, 0.6);
      flex: 1;
      font-size: 11px;
      line-height: 14px;
      margin: 0;
      padding-left: 5px;
    }
  }
  .browser-wrap {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11px;
    text-align: center;
    line-height: 1.365;
    img {
      width: 50px;
      height: 20px;
      opacity: 0.5;
      margin-bottom: 5px;
    }
  }
  .policy-link {
    text-align: center;
    padding-top: 4px;
    margin: 8px auto 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    @media only screen and (max-width: 767.98px) {
      border-top: 0;
    }
    li {
      display: inline-block;
      margin-bottom: 5px;
      &:after,
      &:first-child:before {
        margin: 0 9px;
        content: "-";
        color: rgba(0, 0, 0, 0.6);
      }
      &:after,
      &:first-child:before {
        margin: 0 9px;
        content: "-";
        color: rgba(0, 0, 0, 0.6);
        @media only screen and (max-width: 767.98px) {
          content: "|";
        }
      }
      a {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 15px;
        text-decoration: underline;
      }
    }
  }
  .app-link {
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 18px;
    a {
      margin-bottom: 2px;
      display: inline-block;
      text-align: center;
      img {
        width: 155px;
        height: auto;
      }
    }
    p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 10px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 46px;
        }
      }
    }
  }
  .footer-links {
    background: var(--secondaryClr);
    display: inline-block;
    margin: 20px auto 10px auto;
    padding: 0 15px;
    width: 100%;
    ul {
      text-align: center;
      padding: 15px 0;
      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        margin: 5px 15px;
        &:after {
          content: " ";
          position: absolute;
          right: -16px;
          top: 2px;
          width: 1px;
          height: 14px;
          background: var(--headClr);
        }
        &:last-child {
          margin-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: var(--whiteClr);
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background: linear-gradient(-180deg, #243a48 20%, #172732 91%);
    z-index: 999;
    box-shadow: 0 0px 14px rgb(255 189 0 / 12%);
    ul {
      li {
        position: relative;
        &.main-nav {
          &:before {
            position: absolute;
            content: "";
            top: -4.5333333333vw;
            width: 110%;
            height: 5.3333333333vw;
            background-image: url("./assets/images/main-nav-top-img.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            pointer-events: none;
            margin-left: -0.2666666667vw;
            z-index: -1;
          }
          a {
            height: 16.8vw;
            padding-top: 1.3333333333vw;
            margin-top: -4.5333333333vw;
            img {
              width: 17.8666666667vw;
              height: 16.2666666667vw;
              margin: 0 auto;
              margin-left: -1.8666666667vw;
              z-index: 99;
            }
          }
        }
        a {
          color: var(--whiteClr);
          font-size: 3.2vw;
          text-transform: capitalize;
          text-decoration: none;
          width: 16.8vw;
          height: 13.3333333333vw;
          line-height: 1.1;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1.8666666667vw 2.6666666667vw 0;
          cursor: pointer;
          img {
            width: 5.8666666667vw;
            height: 5.3333333333vw;
            margin: 0 auto 0.5333333333vw;
            &.icon-home {
              width: 6.6666666667vw;
            }
          }
          span {
            width: -webkit-fill-available;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &.active {
            color: var(--primaryClr);
          }
        }
      }
    }
  }
}
.agent-head.crypto-head {
  background: transparent;
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  margin-top: 15px;
  .social-icon {
    width: 12%;
    margin: 0 2%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.button-primary.btn.btn-primary {
  padding: 6px 11px !important;
}
.form-control {
  background: transparent !important;
  color: var(--whiteClr) !important;
  &:focus {
    border-color: var(--primaryClr);
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}
.betslip-content.inline_betslip {
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning {
    p {
      line-height: 1.3;
      margin-bottom: 0;
      @media only screen and (max-width: 767.98px) {
        font-size: 0.8rem;
      }
    }
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      align-items: center;
      li {
        width: 20%;
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--secondaryClr);
          color: var(--whiteClr);
          width: 100%;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #2ebd97;
        color: var(--whiteClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
        &:hover {
          background: #025f18;
        }
      }
    }
    .cancel-btn {
      background: var(--lightClr);
      color: var(--siteRedClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
      &:hover {
        background: var(--siteRedClr);
        color: var(--whiteClr);
      }
    }
  }
}
.betslip_popup {
  .modal-body {
    background: var(--headClr);
  }
}

@media only screen and (min-width: 768px) {
  .header .news {
    display: none;
  }
}

@media only screen and (max-width: 1199.98px) {
  .header_open {
    .navbar {
      transform: translateX(0px) !important;
      .navbar-toggler {
        right: -12px !important;
      }
    }
  }
  .header {
    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-230px);
      width: 230px;
      height: 100%;
      background: var(--bodyClr);
      padding: 1rem;
      z-index: 9;
      transition: all 0.3s;
      .navbar-toggler {
        position: absolute;
        top: 70px;
        right: -30px;
        background: var(--whiteClr);
        padding: 0.15rem 0.25rem;
        border-radius: 25px;
        z-index: 99;
        .navbar-toggler-icon {
          width: 20px;
          height: 22px;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 1rem;
        left: 1rem;
        width: 200px;
        height: 100%;
        display: block !important;
        background: transparent;
        padding: 0;
        margin: 0;
        .navbar-nav {
          width: 100%;
          flex-direction: column;
          a {
            color: var(--whiteClr);
            font-size: 1em;
            text-transform: capitalize;
            width: 100%;
            height: 30px;
            line-height: 1.33;
            margin-bottom: 1.1rem;
            padding: 0;
            &:last-child {
              margin-bottom: 0;
            }
            svg,
            img {
              fill: var(--whiteClr);
              width: 20px;
              height: 20px;
              margin-right: 7px;
            }
            &:hover {
              opacity: 0.8;
            }
            &.logoImg {
              width: fit-content;
              margin-bottom: 2.25rem;
              img {
                width: 150px;
                height: auto;
                @media screen and (max-width: 767.98px) {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .home-content .news {
    display: none;
  }
  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 40%;
      margin: 2%;
    }
  }
  .header {
    .navbar {
      .navbar-toggler {
        border-radius: 0.375rem;
        line-height: 16px;
        padding: 0 0.15rem;
        right: -37px;
        top: 18px;
      }
    }
    &.aftrlgn {
      .logo {
        display: none;
      }
      .headerRight {
        width: 100%;
        padding-right: 0;
      }
      .logo {
        margin-left: 30px;
        margin-right: 0;
      }
    }
    .headerRight {
      .funds ul {
        li:after {
          display: none;
        }
      }
    }
  }
  .custom-tabs {
    padding-top: 0px;
    margin-top: -4px;
    &.sports_page {
      .top_nav {
        background: linear-gradient(180deg, #ffcc2e 0%, #ffbd14 100%);
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 0;
        border-bottom: 0.7vw solid #070707;
        .nav-item {
          position: relative;
          padding-top: 2.6666666667vw;
          margin-left: 1.8666666667vw;
          .nav-link {
            color: #070707;
            font-size: 3.4666666667vw;
            font-weight: bold;
            line-height: 9.6vw;
            padding: 0 1.8666666667vw;
            border-radius: 1.6vw 1.6vw 0 0;
            &.active {
              background: linear-gradient(180deg, #474747 0%, #070707 100%);
              color: var(--primaryClr);
            }
          }
        }
      }
    }
    .sports-name-listing {
      ul {
        padding: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .page-details {
    .game-top-area {
      background-color: #e0e6e6;
      margin-bottom: 0;
      .range {
        display: none;
      }
      .date {
        display: none;
      }
      .title {
        color: #1e1e1e;
        background-image: linear-gradient(
          -180deg,
          rgb(255 189 0) 15%,
          rgb(255 189 0) 100%
        );
        border-color: rgba(51, 51, 51, 0.3);
        line-height: 9.06667vw;
        font-weight: bold;
        border: 1px solid;
        font-size: 12px;
        border-radius: 4.8vw;
        padding: 0 2.4vw;
      }
    }
    .game-points {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      ul {
        li {
          display: flex;
          padding-left: 0;
          border-bottom: 1px solid #7e97a7;
          background: #fff !important;
          .team-name {
            color: #1e1e1e;
            font-size: 4vw;
            font-weight: bold;
            width: 65%;
            padding-left: 1.8666666667vw;
            margin-bottom: 0.25rem;
            flex-direction: revert;
            justify-content: space-between;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .time {
              display: none;
            }
          }
          .status-btns {
            width: 35%;
            display: flex;
            align-items: center;
            .points-status {
              background-color: rgba(0, 0, 0, 0.4);
              color: #fff;
              font-weight: bold;
              text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
              justify-content: center;
              align-items: center;
              border-radius: 0;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            .info-block {
              width: inherit;
              display: none;

              &:nth-child(3),
              &:nth-child(4) {
                display: flex;
                width: 50%;
                border-right: 1px solid #fff;
              }
              &:nth-child(4) {
                border: 0;
              }
              &:first-child {
                margin-left: 0;
                display: none;
              }
            }
            .info-block.no:nth-child(2) {
              display: flex;
              width: 50%;
              border: 0 !important;
            }
          }
        }
      }
    }
  }
  .middleContentSec {
    .container {
      padding: 0;
    }
    .sports_page_banner {
      margin: 0 !important;
      .slick-slide {
        img {
          aspect-ratio: 16 / 6 !important;
        }
      }
      .slick-dots {
        display: none !important;
      }
    }
  }
  .sports_page,
  .custom-tabs {
    .container-fluid {
      padding: 0;
    }
    .tab-pane {
      height: auto !important;
      .game-listing {
        border: 0;
        .title {
          background: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
          line-height: 2.2;
          display: block;
          text-align: center;
          span {
            font-size: 3.7333333333vw;
            font-weight: bold;
            padding: 0;
          }
        }
        .match-info {
          height: auto;
          line-height: 1.6;
          display: block;
          padding: 1.6vw 0 1.8666666667vw 6.6666666667vw;
          .match-name {
            width: 100%;
            padding: 0 2px 2px 0;
            height: 100%;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: relative;
            .breadcrumb {
              font-size: 2.2488755622vmax;
              display: flex;
              align-items: baseline;
              .date_time_info {
                span {
                  padding-left: 23px;
                  display: none;
                }
                .date {
                  color: #777;
                  font-size: 3.2vw;
                  font-weight: 400;
                  line-height: 1.1;
                  margin: 0 1.3333333333vw;
                  margin-top: -1px;
                }
              }
              .teams {
                width: calc(100% - 13.3333333334vw);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.6;
              }
            }
          }
          .status {
            width: 4.2666666667vw;
            height: 4vw;
            border-radius: 0.8vw;
            &::after {
              width: 2.9333333333vw;
              height: 2.9333333333vw;
            }
          }
          .live-match {
            height: 10px;
            width: 10px;
            margin-right: 8px;
            background: #c4cfd6;
            border: 1px solid #366601;
            border-radius: 50%;
          }
          .listing {
            padding-bottom: 2px;
            display: none;
            li {
              width: calc(16.66% - 4px);
            }
          }
        }
        .match-listing-head {
          display: none;
        }
      }
    }
  }
  .betslip_popup {
    .modal-dialog {
      margin: 0;
    }
    .modal-body {
      background: var(--whiteClr);
      border: 2px solid #7e97a7;
    }
    .odds {
      .form-control {
        background: var(--whiteClr) !important;
        color: var(--blackClr) !important;
        font-size: 4vw;
        font-weight: bold;
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        box-shadow: inset 0 0.5333333333vw 0 0 rgba(0, 0, 0, 0.1);
      }
    }
    .auto-stack {
      li {
        margin-right: 0 !important;
        border-right: 1px solid rgb(81 121 146);
        .stack-btn {
          background: linear-gradient(
            -180deg,
            #32617f 20%,
            #1f4258 91%
          ) !important;
          line-height: 2.46;
          padding: 0;
          border: 0;
          border-radius: 0;
        }
      }
    }
    .cancel-btn {
      background: linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%) !important;
      color: var(--blackClr) !important;
      font-size: 4vw !important;
      border: 1px solid #aaa;
    }
    .place-btn {
      background: linear-gradient(180deg, #474747 0%, #070707 100%) !important;
      color: var(--primaryClr) !important;
      font-size: 4vw !important;
      border: 0.2666666667vw solid #222;
    }
  }
}

.contact-social ul {
  display: flex;
  li img {
    width: 60px;
    height: auto;
    margin: 0 15px;
  }
}
.privay-content {
  margin-top: 20px;
  p.privacy-points {
    margin-bottom: 9px;
    width: 20%;
    position: relative;
    border-bottom: 1px solid #fff;
    padding-left: 12px;
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
      top: 6px;
      left: 0;
    }
  }
}
.responsible-game {
  margin-top: 20px;
}
.exchange-popup {
  z-index: 99999999999;
  .modal-content {
    background: #1a1e29;
    border-radius: 0 !important;
    .modal-header,
    .modal-body,
    .modal-footer {
      border: 0;
      padding: 15px;
    }
    .btn-close {
      background-image: url(assets/images/close.svg);
      opacity: 1;
    }
    .balnace-wallet {
      display: flex;
      flex-direction: column;
      .balance-value {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        svg {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          color: #ffbd00;
        }
      }
    }
    .amount-input {
      form {
        margin-top: 5px;
        input {
          background: #fff !important;
          border-width: 2px;
          border-radius: 5px;
          color: #000 !important;
        }
        .exchange-button {
          display: flex;
          justify-content: space-between;
          button {
            font-size: 16px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

input[type="date"].form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
input[type="date"] {
  z-index: 999999;
}
.header.beforeheader {
  button.navbar-toggler {
    display: none;
  }
}

.leftbarSec {
  background: var(--whiteClr);
  width: 200px;
  height: calc(100vh - 105px);
  overflow-y: auto;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media only screen and (max-width: 1199.98px) {
    position: fixed;
    transform: translateX(-100%);
    transition: all 0.3s;
    z-index: 999;
  }
  @media only screen and (max-width: 767.98px) {
    width: 70%;
    z-index: 99999999999;
  }
  .games_link {
    h6 {
      color: var(--primaryClr);
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    ul {
      padding: 10px 0;
      li {
        cursor: pointer;
        border-bottom: 1px solid #e3e3e3;
        > div {
          line-height: 35px;
          display: flex;
          align-items: center;
          padding: 5px 10px;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            top: 0;
            left: -10px;
            width: 2px;
            height: 100%;
            background: var(--primaryClr);
            opacity: 0;
            transition: all 0.3s;
          }
          &:hover {
            &:before {
              opacity: 1;
              left: 0;
            }
          }
          img {
            width: 20px;
            height: auto;
          }
          span {
            color: var(--blackClr);
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin-left: 10px;
            opacity: 0.8;
          }
          .sport-event-count {
            background: var(--primaryClr);
            color: var(--blackClr);
            font-size: 12px;
            margin-left: auto;
            padding: 0.25em 0.4em;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
.middleContentSec {
  width: 100%;
  @media only screen and (min-width: 1200px) {
    width: calc(100% - 600px);
    max-height: calc(100vh - 185px);
    overflow-y: auto;
    padding: 0 5px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: calc(100% - 500px);
    }
  }
}
.rightbarSec {
  background: var(--whiteClr);
  width: 400px;
  height: calc(100vh - 105px);
  overflow-y: auto;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
    width: 300px;
  }
  @media only screen and (max-width: 1199.98px) {
    display: none;
  }
  h6 {
    color: var(--primaryClr);
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
    padding: 5px 10px;
    border-bottom: 1px solid var(--primaryClr);
  }
  .VSlider {
    background: linear-gradient(180deg, #383838 0%, #010101 100%);
    border-radius: 10px;
    padding: 20px 10px;
    margin: 14px 0;
    .slick-slider {
      position: relative;
      .slide_box {
        width: 70%;
        margin: 0 auto;
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.45);
          border-radius: 5px;
        }
      }
      .slick-dots {
        top: 40%;
        bottom: auto;
        right: -40px;
        left: auto;
        transform: rotate(90deg);
        width: auto;
        li {
          width: 32px;
          height: 32px;
          transform: rotate(-90deg);
          button {
            border-radius: 50%;
            text-align: center;
            width: 32px;
            height: 32px;
            line-height: 32px;
            padding: 0;
            &::before {
              color: #ccc;
              font-size: 15px;
              font-family: Arial, Helvetica, sans-serif;
              text-shadow: 1px 1px 3px #555;
              width: 32px;
              height: 32px;
              line-height: 30px;
              opacity: 1;
              transition: all 0.4s;
            }
          }
          &:first-child {
            button {
              &::before {
                content: "01";
              }
            }
          }
          &:nth-child(2) {
            button {
              &::before {
                content: "02";
              }
            }
          }
          &:nth-child(3) {
            button {
              &::before {
                content: "03";
              }
            }
          }
          &.slick-active {
            button {
              background: var(--primaryClr);
              &::before {
                color: var(--blackClr);
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
  .casino_games {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    a {
      position: relative;
      img {
        border-radius: 5px;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(#3f3f4f 10%, #000 100%);
        color: var(--primaryClr);
        font-weight: 700;
        line-height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        opacity: 0;
        border-radius: 5px;
        .game_type {
          font-size: 10px;
          text-transform: uppercase;
          max-width: 90px;
          transition: all 0.4s;
        }
      }
      &:hover {
        .overlay {
          opacity: 0.8;
          .game_type {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  &.matched_bets {
    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
      display: block;
    }
    @media only screen and (max-width: 991.98px) {
      display: none;
    }
    .accordion {
      .accordion-item {
        background-color: #333;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border: 0;
        border-radius: 5px;
        box-shadow: 0 0 10px rgb(0 0 0 / 60%);
        .accordion-header {
          .accordion-button {
            background: transparent;
            width: 100%;
            text-align: left;
            padding: 5px 10px;
            border: 0;
            border-bottom: 1px solid var(--primaryClr);
            border-radius: 0;
            box-shadow: none;
            img {
              width: 20px;
              margin-right: 10px;
            }
            span {
              color: var(--primaryClr);
              font-size: 13px;
              font-weight: 600;
              line-height: 28px;
            }
            &:hover,
            &:focus {
              background: transparent !important;
            }
            &:after {
              content: none;
            }
          }
        }
        .accordion-body {
          color: var(--blackClr);
          padding: 0;
          border: 0;
          .bets_box {
            .bet_info {
              .table {
                background: var(--headClr);
                margin-bottom: 0;
                border-collapse: collapse;
                overflow-x: auto;
                border: 1px solid #222;
                thead {
                  background: #222;
                  display: block;
                  tr {
                    th {
                      color: var(--blackClr);
                      font-size: 12px;
                      font-weight: 600;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 150px;
                      border: 0;
                      &:not(:first-child) {
                        min-width: 60px;
                      }
                      &:first-child {
                        min-width: 35px;
                      }
                      &:last-child {
                        width: 100%;
                      }
                    }
                  }
                }
                tbody {
                  display: block;
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 360px;
                  &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                  }
                  &::-webkit-scrollbar-track {
                    background: #222;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: #333;
                    &:hover {
                      background: #444;
                    }
                  }
                  tr {
                    td {
                      color: var(--blackClr);
                      font-size: 11px;
                      border-color: #333;
                      &:not(:first-child) {
                        min-width: 60px;
                      }
                      &:first-child {
                        min-width: 35px;
                      }
                      &:last-child {
                        width: 100%;
                      }
                      span {
                        color: var(--whiteClr);
                        width: 35px;
                        text-align: center;
                        display: inline-block;
                        border-radius: 4px;
                        &.lay {
                          background: #72bbef;
                        }
                        &.back {
                          background: #faa8ba;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .no_data {
            color: var(--blackClr);
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            text-align: center;
          }
        }
        &.live_tv {
          .accordion-body {
            background: var(--whiteClr);
            .live_tv_box {
              height: 215px;
            }
          }
        }
        &.bets {
          margin-bottom: 0px;
        }
      }
    }
  }
}
